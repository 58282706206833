import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Section from "../components/Section"
import Figure from "../components/Figure"
import { H1, H2, H3, P, Ul } from "../components/VerticalRhythm"
import { Flex, Box } from "rebass"

import PictureSet from "../components/PictureSet"
import imgObj16 from "../imgObjs/imgObj16"

import breakpoints from "../theme/breakpoints"

const IndexPage = () => (
  <Layout>
    <SEO
      title="Psychosoziale Beratung"
      keywords={[`Hebamme`, `Frankfurt`, `Beratung`, `Geesche Martin`]}
    />
    <Figure caption="">
      <PictureSet imgObj={imgObj16} />
    </Figure>
    <Section>
      <Flex flexDirection={"row-reverse"}>
        <Box width={[1, 1, 1 / 2]}>
          <P textAlign={"right"}>
            <em>
              „Schwierige Zeiten lassen uns Entschlossenheit und innere Stärke
              entwickeln.“ <br />
            </em>
            — Dalai Lama
          </P>
        </Box>
      </Flex>
      <H1>Psychosoziale Beratung</H1>
      <P>Ich biete Ihnen Psychosoziale Beratung an:</P>
      <Ul>
        <li>in der Schwangerschaft</li>
        <li>nach schweren Geburten und Fehlgeburten</li>
        <li>als Krisenintervention</li>
      </Ul>
      <P>
        Schwangerschaft und Geburt sind bedeutende Lebensphasen. Doch manchmal
        verlaufen sie anders, als Sie es sich gewünscht oder vorgestellt haben…
      </P>
      <H3>Schwangerschaft</H3>
      <P>
        In der Schwangerschaft können ungelöste Konflikte aus der Vergangenheit
        reaktiviert werden und Ihre Stimmung trüben. Ängste und Sorgen werden zu
        Begleitern.
      </P>
      <P>
        Die neue Rolle als Mutter, der veränderte Tagesablauf oder die sich
        wandelnde Beziehung zum Partner können Themen sein, die zu Belastung und
        Anspannung führen.
      </P>
      <P>
        Gern begleite ich Sie, das Unbegreifliche und Neue begreiflich zu
        machen, indem ich nachfrage, Sie ermutige und unterstütze, damit Sie mit
        Zuversicht der neuen Lebensphase entgegenblicken.
      </P>
      <H3>Geburt</H3>
      <P>
        Durch die Geburt fühlen Sie sich belastet, überfordert und sehen keine
        Möglichkeit, über das Erlebte zu sprechen und es zu verarbeiten.
      </P>

      <P>
        Mit der psychosozialen Beratung möchte ich Sie unterstützen zu
        verstehen, was passiert ist. Bei mir finden Sie einen „Raum“, um über
        das Erlebte zu sprechen und Ihren Gefühlen Ausdruck zu verleihen.
      </P>
      <P>Dabei können folgende Themen bearbeitet werden:</P>
      <Ul>
        <li>
          eine ausführliche Schilderung der Geburt: warum ist was, wann, wie
          passiert
        </li>
        <li>ein Herausarbeiten von positiven Anteilen während der Geburt</li>
        <li>
          eine Anerkennung der Trauer und der Enttäuschung über das
          Geburtserlebnis
        </li>
        <li>der Abbau von Schuld-, Scham,- und Versagensgefühlen</li>
      </Ul>
      <P>
        Gemeinsam erarbeiten wir, dass Sie Ihre persönliche Leistung im
        Geburtsgeschehen und Ihre Trauer anerkennen. So kann Ihre Wunde heilen,
        damit Sie wieder mit mehr Energie und Freude Ihren Weg weitergehen.
      </P>
      <P>Auch Ihr Partner ist herzlich willkommen.</P>

      <H3>Mehr Infos</H3>
      <P>
        In der Schwangerschaft werden die Kosten der psychosozialen Beratung von
        der Krankenkasse übernommen. In welcher Höhe eine Kostenübernahme im
        Wochenbett erfolgen kann, muss im Einzelfall geklärt werden.
      </P>
      <P>
        Mehr Info auf{" "}
        <a href="http://krisenberatung-frankfurt.com">
          krisenberatung-frankfurt.com
        </a>
        .
      </P>
    </Section>
  </Layout>
)

export default IndexPage
